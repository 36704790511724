"use client";

import { useState } from "react";

import { Button, buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useSidebar } from "@/hooks/useSidebar";
import { cn, isAdminUser } from "@/lib/utils";
import { ChevronRight } from "lucide-react";
import { NavItems } from "./constants/side-nav";
import { SideNav } from "./side-nav";
import { useUserContext } from "@/components/providers/user-provider";
import { ClientUser } from "@/types/ClientUser";
import Link from "next/link";
import { VscFeedback } from "react-icons/vsc";
import { usePathname } from "next/navigation";

interface SidebarProps {
  className?: string;
  user: ClientUser | null;
}

export default function Sidebar({ className, user }: SidebarProps) {
  const { isOpen, toggle } = useSidebar();
  const [swith, setSwitch] = useState(false);
  const userContext = useUserContext();
  const isAdmin = isAdminUser(user);
  const pathname = usePathname();

  const handleToggle = () => {
    setSwitch(true);
    toggle();
    setTimeout(() => setSwitch(false), 500);
  };

  if (userContext.imageFullScreen) {
    return;
  }

  const navItems = NavItems.filter(
    (l) => !(!isAdmin && l.title.toLowerCase().includes("tournament")),
  );

  return (
    <nav
      className={cn(
        `relative hidden border-r pt-2 sm:flex flex-col`,
        swith && "duration-500",
        isOpen ? "w-52" : "w-[78px]",
        className,
      )}
    >
      <div className="flex flex-grow">
        <div className="px-3 py-2 flex-grow flex flex-col justify-between">
          <div className="mt-3 space-y-1">
            <SideNav
              className="text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100"
              items={navItems}
            />
          </div>
          <div>
            <Link
              href={"/contact_us"}
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "group relative flex h-12 justify-start",

                pathname.startsWith("/contact_us") &&
                  "bg-muted bg-gray-200 font-bold hover:bg-muted",
              )}
            >
              <VscFeedback className={"h-5 w-5  text-[#f1c40f]"} />
              {isOpen ? (
                <span
                  className={cn(
                    "text-base duration-200 ml-1",
                    !pathname.startsWith("/contact_us"),
                  )}
                >
                  Contact Us
                </span>
              ) : (
                <></>
              )}
            </Link>
          </div>
        </div>
      </div>
      <div className="px-3">
        <Separator />
        <Button
          onClick={handleToggle}
          className={cn(
            "my-4 h-10 w-full bg-foreground",
            isOpen && "rotate-180",
          )}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </nav>
  );
}

"use client";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { NavItem } from "./constants/NavItem";
import { useSidebar } from "@/hooks/useSidebar";
import { usePathname } from "next/navigation";

interface SideNavProps {
  items: NavItem[];
  setOpen?: (open: boolean) => void;
  className?: string;
}

export function SideNav({ items, setOpen, className }: SideNavProps) {
  const { isOpen, toggle } = useSidebar();
  const pathname = usePathname();
  const [openItem, setOpenItem] = useState<number | null>(null);

  useEffect(() => {
    const openByPath = items.findIndex((item) => {
      const basePath = item.basePath || item.href;
      if (
        item.excludePaths &&
        item.excludePaths.some((path) => pathname.startsWith(path))
      ) {
        return false;
      }
      return pathname.startsWith(basePath);
    });

    setOpenItem(openByPath);
  }, [pathname]);

  return (
    <nav className="space-y-2">
      {items.map((item, i) => (
        <Link
          href={item.href}
          key={`${item.title}-${i}-link`}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "group relative flex h-12 justify-start",

            openItem === i && "bg-muted bg-gray-200 font-bold hover:bg-muted",
          )}
        >
          <item.icon
            className={cn("h-5 w-5", item.color)}
            key={`${item.title}-${i}-icon`}
          />
          {isOpen ? (
            <span
              className={cn("text-base duration-200 ml-1", !(openItem === i))}
              key={`${item.title}-${i}-span`}
            >
              {item.title}{" "}
            </span>
          ) : (
            <></>
          )}
        </Link>
      ))}
    </nav>
  );
}

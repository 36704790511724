import {
  LayoutDashboard,
  Gamepad,
  Play,
  Circle,
  SquareLibrary,
  CaseSensitive,
  User,
} from "lucide-react";
import { NavItem } from "./NavItem";

export const NavItems: NavItem[] = [
  {
    title: "Games",
    icon: LayoutDashboard,
    href: "/games",
    color: "text-sky-500",
    basePath: "/game",
    excludePaths: ["/game/new_batch"],
  },

  {
    title: "Add Scoresheet",
    icon: Gamepad,
    href: "/game/new_batch",
    color: "text-pink-500",
  },

  {
    title: "Add PGN",
    icon: CaseSensitive,
    href: "/correct_pgn",
    color: "text-red-500",
  },
  {
    title: "Tournaments",
    icon: Circle,
    href: "/tournaments",
    color: "text-green-500",
  },

  {
    title: "Add Tournament",
    icon: Play,
    href: "/tournament/new",
    color: "text-orange-500",
  },

  {
    title: "Collections",
    icon: SquareLibrary,
    href: "/collections/me",
    color: "text-purple-500",
    basePath: "/collections",
  },

  {
    title: "Profile",
    icon: User,
    href: "/account",
    color: "text-orange-500",
  },
];

"use client";

import React from "react";
import Footer from "./Footer";

export default function Wrapper({
  children,
  withFooter,
}: {
  children: React.ReactNode;
  withFooter?: boolean;
}) {
  return (
    <nav
      id="layout-children-wrapper"
      className="flex flex-col min-h-0 duration-500 w-full pt-2 overflow-auto"
    >
      <section className="w-full">{children}</section>
      {withFooter && <Footer />}
    </nav>
  );
}
